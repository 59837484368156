import axios from 'axios'
import { Message } from 'element-ui'
import router from 'vue-router'
import { getToken,removeToken,getVersionMsg,removeVersionMsg } from './auth'

const TOKEN_INVALID = 'Token认证失败，请重新登录'
const NETWORK_ERROR = '网络请求异常，请稍后重试'

function request(requestMethod, url, data, node) {
  // 获取本地token
  const token = getToken()
  // 获取本地版本信息
  const version = getVersionMsg()
  // 配置请求头
  const config = {
    headers: {}, // 请求头
    // timeout: 5000
  }

  if (token) config.headers['token'] = token
  if (version) config.headers['version'] = version

  config.method = requestMethod // 请求方式

  // 根据请求方式的传参key值进行变化
  if (requestMethod === 'get' && data || requestMethod === 'delete' && data) {
    config.params = data
  } else if (requestMethod === 'post' || requestMethod === 'put') {
    config.data = data
  }
  // if (node) {
  //   config.url = process.env.VUE_APP_NODE_API + url // url = base url + request url
  // } else {
  //   config.url = process.env.VUE_APP_BASE_API + url // url = base url + request url
  // }

  // 获取访问地址
  var urlStr = window.location.href
  var urlArr = urlStr.split("#")
  var urlArrPrev = urlArr[0]
  if (node) {
    config.url = process.env.VUE_APP_NODE_API + url // url = base url + request url
  } else if(urlArrPrev == 'http://58.246.65.68:8201/'){
    config.url = process.env.VUE_APP_SCENCE_API + url
  } else if(urlArrPrev == 'http://mes.bbelc.cn:8201/'){
    config.url = process.env.VUE_APP_MES_API + url
  } else if(urlArrPrev == 'http://mes.fortune-gas.com:8020/'){
    config.url = 'http://mes.fortune-gas.com:8020/' + url
  } else {
    config.url = process.env.VUE_APP_BASE_API + url // url = base url + request url
  }

  return new Promise((resolve, reject) => {
    axios(config)
      .then(res => {
        if (res.data.errorCode === 0) {
          resolve(res.data.result)
        } else if (res.data.errorCode === 2) {
          Message.error(TOKEN_INVALID)
          sessionStorage.clear()
          removeToken()
          removeVersionMsg()
          setTimeout(() => {
            router.push('/login')
          }, 1500)
          reject(TOKEN_INVALID)
        } else if (res.data.errorCode === 6) {
          // 说明除了报错外还要刷新页面
          Message.error(Array.isArray(res.data.result) ? res.data.result.join(',') : res.data.result || res.data.errorMsg)
          reject(false)
          setTimeout(() => {
            window.location.reload
          }, 2000)
        } else {
          Message.error(Array.isArray(res.data.result) ? res.data.result.join(',') : res.data.result || res.data.errorMsg)
          reject(false)
        }
      })
      .catch(() => {
        Message.error(NETWORK_ERROR)
        reject(false)
      })
  })
}

function exportRequest(requestMethod, url, data, node) {
  // 获取本地token
  const token = getToken()
  // 获取本地版本信息
  const version = getVersionMsg()
  // 配置请求头
  const config = {
    headers: {}, // 请求头
    // timeout: 5000,
    responseType: 'arraybuffer'
  }

  if (token) config.headers['token'] = token
  if (version) config.headers['version'] = version

  config.method = requestMethod // 请求方式

  // 根据请求方式的传参key值进行变化
  if (requestMethod === 'get' && data || requestMethod === 'del' && data) {
    config.params = data
  } else if (requestMethod === 'post' || requestMethod === 'put') {
    config.data = data
  }

  // if (node) {
  //   config.url = process.env.VUE_APP_NODE_API + url // url = base url + request url
  // } else {
  //   config.url = process.env.VUE_APP_BASE_API + url // url = base url + request url
  // }
  // 获取访问地址

  var urlStr = window.location.href
  var urlArr = urlStr.split("#")
  var urlArrPrev = urlArr[0]
  if (node) {
    config.url = process.env.VUE_APP_NODE_API + url // url = base url + request url
  } else if(urlArrPrev == 'http://58.246.65.68:8201/'){
    config.url = process.env.VUE_APP_SCENCE_API + url
  } else if(urlArrPrev == 'http://mes.bbelc.cn:8201/'){
    config.url = process.env.VUE_APP_MES_API + url
  } else if(urlArrPrev == 'http://mes.fortune-gas.com:8020/'){
    config.url = 'http://mes.fortune-gas.com:8020/' + url
  } else {
    config.url = process.env.VUE_APP_BASE_API + url // url = base url + request url
  }

  return new Promise((resolve, reject) => {
    axios(config)
      .then(res => {
        if (res) {
          resolve(res)
        }
      })
  })
}
// get请求封装
function get(url, params) {
  return request('get', url, params)
}
function del(url, params) {
  return request('delete', url, params)
}
// post请求封装
function post(url, postData) {
  return request('post', url, postData)
}
// put请求封装
function put(url, postData) {
  return request('put', url, postData)
}
function exportGet(url, postData) {
  return exportRequest('get', url, postData)
}
export default ({
  post,
  get,
  put,
  del,
  exportGet
})
