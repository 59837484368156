import CryptoJS from 'crypto-js'

const key = CryptoJS.enc.Utf8.parse('shanghaimubai123') // 十六位十六进制数作为密钥
const iv = CryptoJS.enc.Utf8.parse('shanghaimubai123') // 十六位十六进制数作为密钥偏移量

// 加密方法
export function Encrypt(word) {
  const encrypted = CryptoJS.AES.encrypt(word, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  return encrypted.ciphertext.toString()
}
// 解密方法
export function Decrypt(word) {
  const encryptedHexStr = CryptoJS.enc.Hex.parse(word);
  const encryptedBase64Str = CryptoJS.enc.Base64.stringify(encryptedHexStr);
  const decrypted = CryptoJS.AES.decrypt(encryptedBase64Str, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
}