import Cookies from 'js-cookie'
import baseSettingApi from '@/api/sets/base-setting'

const state = {
  sidebar: {
    opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
    secondOpened: Cookies.get('secondSidebarStatus') ? !!+Cookies.get('secondSidebarStatus') : true,
    fullScreen: Cookies.get('fullScreenStatus') ? !!+Cookies.get('fullScreenStatus') : false,
    currentMainMenuName: '',
    withoutAnimation: false
  },
  contentWidth: document.documentElement.clientWidth,
  contentHeight: document.documentElement.clientHeight + 66,
  device: 'desktop',
  pageSize: {},
  tableRowHeight: localStorage.getItem('tableRowHeight') || '30'
}

const mutations = {
  TOGGLE_SIDEBAR: state => {
    state.sidebar.opened = !state.sidebar.opened
    state.sidebar.withoutAnimation = false
    if (state.sidebar.opened) {
      Cookies.set('sidebarStatus', 1)
    } else {
      Cookies.set('sidebarStatus', 0)
    }
  },
  OPEN_SIDEBAR: (state) => {
    Cookies.set('sidebarStatus', 1)
    state.sidebar.opened = true
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    Cookies.set('sidebarStatus', 0)
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  OPEN_SECOND_SIDEBAR: (state) => {
    Cookies.set('secondSidebarStatus', 1)
    state.sidebar.secondOpened = true
  },
  CLOSE_SECOND_SIDEBAR: (state, withoutAnimation) => {
    Cookies.set('secondSidebarStatus', 0)
    state.sidebar.secondOpened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  OPEN_FULL_SCREEN: (state) => {
    Cookies.set('fullScreenStatus', 1)
    state.sidebar.fullScreen = true
  },
  CLOSE_FULL_SCREEN: (state, withoutAnimation) => {
    Cookies.set('fullScreenStatus', 0)
    state.sidebar.fullScreen = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device
  },
  UPDATE_STATE: (state, payload) => {
    if (payload.data) {
      state[payload.name] = { ...state[payload.name], ...payload.data }
    } else {
      state = { ...state, ...payload }
    }
  },
  UPDATE_SINGLE: (state, { name, data }) => {
    state[name] = data
  },
  UPDATE_PAGE_SIZE: (state, pageSize) => {
    state.pageSize = pageSize
  },
  UPDATE_TABLE_ROW_HEIGHT: (state, tableRowHeight) => {
    state.tableRowHeight = tableRowHeight
    localStorage.setItem('tableRowHeight', tableRowHeight)
  },
}

const actions = {
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  openSideBar({ commit }) {
    commit('OPEN_SIDEBAR')
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation)
  },
  openSecondSideBar({ commit }) {
    commit('OPEN_SECOND_SIDEBAR')
  },
  closeSecondSideBar({ commit }, { withoutAnimation }) {
    commit('CLOSE_SECOND_SIDEBAR', withoutAnimation)
  },
  openFullScreen({ commit }) {
    commit('OPEN_FULL_SCREEN')
  },
  closeFullScreen({ commit }, { withoutAnimation }) {
    commit('CLOSE_FULL_SCREEN', withoutAnimation)
  },
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  },
  updateState({ commit }, payload) {
    commit('UPDATE_STATE', payload)
  },
  updateSingle({ commit }, payload) {
    commit('UPDATE_SINGLE', payload)
  },
  updateTableRowHeight({ commit }, payload) {
    commit('UPDATE_TABLE_ROW_HEIGHT', payload)
  },
  async updatePageSize({ commit }) {
    const res = await baseSettingApi.getCode({ code: 'page_size_config' })
    if (res) {
      commit('UPDATE_PAGE_SIZE', JSON.parse(res.value))
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
