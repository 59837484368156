// 美化打印实现方法
import api from '@/api/columnsSetApi'
import { nameChangeForBB } from '@/utils/index'

const prettyLogWc = () => {

  const isEmpty = (value) => {
    return value == null || value === undefined || value === ''
  }
  const prettyPrint = (title, text, color) => {
    if (typeof text === 'object') {
      console.log(
        `%c ${title} %o`,
        `background:${color};border:1px solid ${color}; padding: 1px; border-radius: 2px 0 0 2px; color: #fff;`,
        text
      )
    } else {
      console.log(
        `%c ${title} %c ${text} %c`,
        `background:${color};border:1px solid ${color};font-weight:bold; padding: 1px; border-radius: 2px 0 0 2px; color: #fff;`,
        `border:1px solid ${color}; padding: 1px; border-radius: 0 2px 2px 0; color: ${color};`,
        'background:transparent'
      )
    }
  }
  const info = (textOrTitle, content = '') => {
    const title = isEmpty(content) ? 'Info' : textOrTitle
    const text = isEmpty(content) ? textOrTitle : content
    prettyPrint(title, text, '#909399')
  }
  const error = (textOrTitle, content = '') => {
    const title = isEmpty(content) ? 'Error' : textOrTitle
    const text = isEmpty(content) ? textOrTitle : content
    prettyPrint(title, text, '#F56C6C')
  }
  const warning = (textOrTitle, content = '') => {
    const title = isEmpty(content) ? 'Warning' : textOrTitle
    const text = isEmpty(content) ? textOrTitle : content
    prettyPrint(title, text, '#E6A23C')
  }
  const success = (textOrTitle, content = '') => {
    const title = isEmpty(content) ? 'Success ' : textOrTitle
    const text = isEmpty(content) ? textOrTitle : content
    prettyPrint(title, text, '#67C23A')
  }
  return {
    info,
    error,
    warning,
    success
  }
}

window.consoleSelf = prettyLogWc()

/*
* @description:处理页面展示字段别名,显示隐藏等等
* @param:routeName:配置的模块/菜单 名称
* @param:columns:页面展示字段
* @return:新的展示字段
* */
async function handleColumns(routeName, columns) {
  const res = await api.getColumnsDetail({ name: routeName }) || []
  columns.forEach(item => {
    const one = res.filter(i => i.name === item.label)
    if (one && one.length > 0) {
      // 处理字段别名
      if (one[0].newName) {
        item.label = one[0].newName
      }
      // 处理输入格式 正则规则  仅对于输入型表格生效
      if (one[0].pattern && item.colSpan) {
        item.rules = [
          {
            pattern: new RegExp(one[0].pattern),
            message: one[0].errMessage || '请输入正确格式',
            trigger: 'blur'
          }
        ]
      }
      // 处理显隐 仅对于输入型表格生效
      if (one[0].showFlag === 0 && item.colSpan) {
        item.hidden = true
      }
      //处理是否必填
      if (one[0].showFlag && item.colSpan) {
        if (one[0].required) {
          item.required = true
        } else {
          item.required = false
        }
      }
    }
  })
  return columns
}

window.handleColumns = handleColumns

