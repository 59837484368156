import server from '@/api/user'
import { getToken, removeToken } from '@/utils/auth'
import router from '@/router'
import user from '@/api/user'
import userManage from '@/api/sets/user-managment'
import baseSetting from '@/api/sets/base-setting'
import watermark from '@/utils/watermark.js'

const getDefaultState = () => {
  return {
    token: getToken(),
    lockScreen: false,
    name: '',
    avatar: '',
    roles: [],
    userInfo: {},
    userDetail: {},
    companyInfo: {},
    currentGanttBlocksHeights: [],
    priorityList: [],
    currentSelectIdx: {},
    searchIndexs: {}
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo
  },
  SET_USER_DETAIL: (state, userDetail) => {
    state.userDetail = userDetail
  },
  SET_LOCK_SCREEN: (state, lockScreen) => {
    state.lockScreen = lockScreen
  },
  SET_COMPANYINFO: (state, companyInfo) => {
    state.companyInfo = companyInfo
  },
  SET_HEIGHT: (state, currentGanttBlocksHeights) => {
    state.currentGanttBlocksHeights = currentGanttBlocksHeights
  },
  SET_PRIORITY: (state, priorityList) => {
    state.priorityList = priorityList
  },
  SET_CURRENTSELECTIDX: (state, currentSelectIdx) => {
    state.currentSelectIdx = currentSelectIdx
  },
  SET_SEARCHINDEXS: (state, searchIndexs) => {
    state.searchIndexs = searchIndexs
  }
}

const actions = {
  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      server.getLoginInfo().then(async res => {
        // 获取租户基础路由
        const baseRouters = await baseSetting.getCode({ code: 'base_router' })
        const baseCode = await baseSetting.getCode({ code: 'member_code' })
        const { userName, authority, token } = res
        const webBaseRouters = JSON.parse(baseRouters.value).web
        // console.log('baseRouters全局基础所有路由 :>> ', baseRouters);
        // console.log('webBaseRouters基础路由web端 :>> ', webBaseRouters);
        // console.log('authority租户的路由信息 :>> ', authority);
        let authList = []
        authority.split('|||').forEach(item => {
          if(item){
            item = JSON.parse(item)
            const filterChecked = item.checked.filter(c => webBaseRouters.includes(c))
            const filterHalf = item.half.filter(c => webBaseRouters.includes(c))
            authList = [...authList, ...filterChecked, ...filterHalf]
          }
        })
        commit('SET_NAME', userName)
        commit('SET_TOKEN', token)
        commit('SET_AVATAR', 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif')
        commit('SET_ROLES', authList)
        // console.log('authList用户的最终路由信息 :>> ', authList);
        commit('SET_USERINFO', res)
        sessionStorage.setItem('userInfo', JSON.stringify(res))
        const code = baseCode.value === 'mubyte' ? 'mubai' : baseCode.value
        sessionStorage.setItem('memberCode', code)
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  // set user detail
  async getUserDetail({ commit, state }) {
    const userInfo = state.userInfo
    const res = await userManage.getUserDetail({ id: userInfo.id })
    return new Promise((resolve, reject) => {
      if (res) {
        commit('SET_USER_DETAIL', res)
        resolve(res)
      }
      reject(res)
    })
  },
  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      removeToken() // must remove  token  first
      commit('RESET_STATE')
      resolve()
    })
  },
  async logout() {
    await user.logout()
    sessionStorage.clear()
    removeToken()
    watermark.close()
    router.push({ name: 'login' })
  },
  async priorityStatusList({ commit, state }) {
    const res = await baseSetting.getCode({ code: 'plan_config' })
    return new Promise((resolve, reject) => {
      if (res) {
        const val = res.value ? JSON.parse(res.value) : []
        commit('SET_PRIORITY', val)
        sessionStorage.setItem('priorityList', val)
        resolve(res)
      }
      reject(res)
    })
  },
  // 锁屏
  lockScreen({ commit }, status) {
    return new Promise(resolve => {
      sessionStorage.setItem('lock', status)
      commit('SET_LOCK_SCREEN', status)
      resolve()
    })
  },
  companyInfo({ commit }, status) {
    return new Promise(resolve => {
      sessionStorage.setItem('companyInfo', JSON.stringify(status))
      commit('SET_COMPANYINFO', status)
      resolve()
    })
  },
  currentGanttBlocksHeights({ commit }, height) {
    commit('SET_HEIGHT', height)
  },
  currentSelectIdx({ commit }, currentSelectIdx) {
    commit('SET_CURRENTSELECTIDX', currentSelectIdx)
  },
  searchIndexs({ commit }, searchIndexs) {
    commit('SET_SEARCHINDEXS', searchIndexs)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

