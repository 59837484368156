import ExcelJs from 'exceljs'
import saveAs from 'file-saver'

function json_to_array(key, jsonData){
  return jsonData.map(v => key.map(j => { return v[j] }));
}

export function export_json_to_excel({
  header = [],
  key = [],
  data = [],
  content = [],
  filename,
  sheetName = filename,
  merges = [],
  bookType = 'xlsx'
} = {}) {
  const workBook = new ExcelJs.Workbook()
  const ws = workBook.addWorksheet(sheetName)
  const arr = json_to_array(key, data)
  arr.unshift(header)
  if (content) arr.unshift(content)
  header.forEach((item, index) => {
    ws.getColumn(index + 1).width = 20
  })
  ws.addRows(arr)
  if (merges.length) {
    ws.mergeCells(...merges)
  }
  ws.eachRow((row, rowNumber) => {
    if (rowNumber > 2) {
      row.eachCell((cell) => {
        // 如果是第一列，全部标红
        if (cell.address.indexOf('A') !== -1) {
          cell.font = {
            color: { argb: 'F56C6C' }
          }
        }
        // 从第二列开始给有错误的单元格添加标记
        if (cell.address.indexOf('A') === -1) {
          const reasonAndValue = cell.value.split(':')
          cell.value = reasonAndValue[0]
          if (reasonAndValue[1]) {
            cell.note = reasonAndValue[1]
            cell.font = {
              color: { argb: 'F56C6C' }
            }
          }
        }
      })
    }
  })
  workBook.xlsx.writeBuffer().then(buffer => {
    saveAs(new Blob([buffer], {
      type: 'application/octet-stream'
    }), `${filename}.${bookType}`)
  })
}

export function export_json_to_excel2({
  header = [],
  key = [],
  data = [],
  filename,
  sheetName = filename,
  merges = [],
  bookType = 'xlsx'
} = {}) {
  const workBook = new ExcelJs.Workbook()
  const ws = workBook.addWorksheet(sheetName)
  const arr = json_to_array(key, data)
  arr.unshift(header)
  header.forEach((item, index) => {
    ws.getColumn(index + 1).width = 20
  })
  ws.addRows(arr)
  if (merges.length) {
    ws.mergeCells(...merges)
  }
  workBook.xlsx.writeBuffer().then(buffer => {
    console.log('导出了')
    saveAs(new Blob([buffer], {
      type: 'application/octet-stream'
    }), `${filename}.${bookType}`)
  })
}




