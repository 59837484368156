module.exports = {
  title: '',
  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: true,

  /**
   * @type {boolean} true | false
   * @description Whether need tagsView
   */
  tagsView: true,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true,

  namespace: 'qiqi',
  // 用于页面权限路由设置的账户/密码  加密的
  setRouteUserNameMd5:'56f2c2c8560d1c01be2e40fd1a78f862',
  setRoutePasswordMd5:'9933bd1481e4b7735385d5662e3b56f5',
}
