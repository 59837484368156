import storge from './storage'

export function getToken() {
  return storge.getItem('token')
}

export function setToken(token) {
  return storge.setItem('token', token)
}

export function removeToken() {
  return storge.clearItem('token')
}

export function getVersionMsg() {
  return storge.getItem('version')
}

export function setVersionMsg(version) {
  return storge.setItem('version', version)
}

export function removeVersionMsg() {
  return storge.clearItem('version')
}
