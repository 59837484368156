const watermark = {
  set: function (text1, text2) {
    if (window.observer) {
      window.observer.disconnect();
    }
    if (document.querySelector(".watermark")) {
      document.querySelector(".watermark").remove();
    }
    var canvas = document.createElement("canvas");
    canvas.width = 600;
    canvas.height = 200;
    canvas.style.display = "none";
    var shuiyin = canvas.getContext("2d");
    // 控制文字的旋转角度和上下位置
    shuiyin.rotate((-10 * Math.PI) / 180);
    shuiyin.translate(-50, 20);
    //文字颜色
    shuiyin.fillStyle = "#e9e9e9";
    //文字样式
    shuiyin.font = "100 16px Microsoft JhengHei";
    shuiyin.fillText(text1, canvas.width / 3, canvas.height / 2);
    shuiyin.fillText(
      text2 || "",
      canvas.width / 3 + 300,
      canvas.height / 2 + 40
    );
    /* 新建一个用于填充canvas水印的标签，之所以没有直接在body上添加，是因为z-index对个别内容影响，才考虑的不用body */
    var watermark = document.createElement("div");
    const styleStr = `
      position:fixed;
      top:0;
      left:0;
      width:100vw;
      height:100vh;
      z-index:99;
      pointer-events:none;
      background-repeat:repeat;
      mix-blend-mode: multiply;
      background-image:url('${canvas.toDataURL("image/png")}')`;
    watermark.setAttribute("style", styleStr);
    watermark.classList.add("watermark");
    document.body.appendChild(watermark);

    //此方法是防止用户通过控制台修改样式去除水印效果
    /* MutationObserver 是一个可以监听DOM结构变化的接口。 */
    window.observer = new MutationObserver(() => {
      const wmInstance = document.querySelector(".watermark");
      if (
        (wmInstance && wmInstance.getAttribute("style") !== styleStr) ||
        !wmInstance
      ) {
        //如果标签在，只修改了属性，重新赋值属性
        if (wmInstance) {
          wmInstance.setAttribute("style", styleStr);
        } else {
          document.body.appendChild(watermark);
        }
      }
    });
    window.observer.observe(document.body, {
      attributes: true,
      subtree: true,
      childList: true,
    });
  },
  close: function () {
    /* 关闭页面的水印，即要移除水印标签 */
    // window.observer.disconnect()
    // let watermark = document.querySelector(".watermark");
    // document.body.removeChild(watermark);
  },
};
export default watermark;
